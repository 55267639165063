@import "../inc/base";

[data-page*="article"] {

	article[data-zone] {
		margin-top: var(--marge);

		figure {

			&[data-zone="photo"],
			&[data-zone="video"] {
				margin-left: calc(var(--marge-exterieure-grille) * -1);
			}

			&[data-zone="photo"] {
				img {
					&[data-mode-article]:not([data-mode-article*="grand"]) {
						display: none;
					}

					aspect-ratio: 1.4;

					@include moyen {
						aspect-ratio: 3/2;
					}

					@include geant {
						aspect-ratio: 2;
					}

					display: block;
				}
			}

			&[data-zone="video"] {
				width: 100%;
				height: 100vh;
				max-height: 50vh;

				>* {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	article[data-zone="article"] {
		display: flex;
		// flex-direction: column;
		// @include gap();
		@include columnGap();

		.chapo {
			margin: 0;

			@include moyen {
				// width: var(--largeur-6-colonnes);
				margin-right: calc(var(--largeur-colonne) + var(--marge));
			}

			@include tres-grand {
				// width: var(--largeur-8-colonnes);
			}
		}



		@include moyen {
			@include grid(('all':'content'));
			// display: grid;
			// grid-template-columns: repeat(9, 1fr);

			>*:not(.content):not(.side) {
				@include span(('all':'content'));
			}

			.content {
				@include span(('all':'content', 'petit':5, 'moyen':5, 'grand':6));

				.twitter-tweet.twitter-tweet-rendered,
				iframe,
				embed {
					margin: 0 auto !important;
				}

				>*:not(figure) {
					margin: 0;
				}


				.side {
					display: none;
				}
			}

			.side {
				@include span(('all':2, 'grand':('span':2, 'start':8)));

			}
		}

		@include grand {
			.content {
				display: flex;




				@include columnGap(var(--marge-grande));
			}
		}

		.content {

			&,
			&>*,
			picture,
			p>picture,
			>p>a,
			>p {
				img[width][height] {
					max-width: 100%;
					height: auto;
				}
			}

			> {

				a,
				[data-zone="photo"] {
					@include marginInline(calc(-1 * var(--marge-exterieure-grille)));
				}

				a,
				figure[data-mode],
				[data-zone="photo"] {

					@include grid-moyenne {
						@include marginInline(0);
						width: calc(100% + (1 * (var(--largeur-colonne) + var(--marge))));
						transform: none;
					}

					@include grid-grande {
						@include marginInline(0);
						width: calc(100% + (2 * (var(--largeur-colonne) + var(--marge))));
						transform: none;
					}
				}

				h3 {
					font-size: var(--taille-texte-grand);
					color: var(--color-text-default);
				}

				p {
					letter-spacing: -0.18px;
					line-height: 1.4em;
				}
			}
		}

		@include moins-que-grand {

			.content {
				.side {
					display: none;
					text-align: center;
					display: block;

					// float: right;
					// margin: 0 0 var(--marge) var(--marge);
					ul {
						display: flex;
						@include gap(var(--marge), var(--marge-petite));
						justify-content: center;
						flex-wrap: wrap;
					}
				}

				>*:not(:first-child) {
					margin-top: var(--marge-petite);
				}

				.chapo {
					font-size: var(--taille-texte-grand-smart);
					line-height: 1em;
				}
			}

			.side {
				display: none;
			}
		}


		@include moyen {
			.content {

				>h3 {
					font-size: var(--taille-texte-petit-smart);
				}

				p {
					letter-spacing: 0;
					line-height: 1.4em;
				}
			}
		}


		// .content {}
	}

	article.article,
	article[data-zone="article"] {
		margin-top: var(--marge-petite);

		@include headerArticle();
	}

	>.chapo {
		margin-top: var(--marge);
		margin-bottom: var(--marge);
		/*            @include grande-grille {
                display: grid;
                grid-template-columns: repeat(9, [col] 1fr);
                >span {
                    grid-column: span 8;
                }
            }*/
	}

	.signature {
		margin: 0;
		// margin-top: var(--marge);
		font-family: var(--police-sans-serif);
		font-size: var(--taille-texte-moyen);
		font-weight: normal;
		font-weight: var(--font-weight-bold);
	}

	.ps {
		// margin-top: calc(-1 * var(--marge-petite));

		max-width: 50%;
		font-size: var(--taille-texte-petit);
		font-family: sans-serif;
	}
}