@import "../inc/base";

[data-zone="top"] {

    @include headerArticle();

    >[data-zone="photo"] {
        display: none
    }

    [data-zone="interface"] {
        margin-bottom: var(--marge);
        overflow: hidden;
        @include grille(7, 9);

        // grid-auto-rows: 1fr;
        [data-zone="numeros"] {
            max-height: 50vh;
            margin-top: 2vh;
            @include grilleSpan(2);
            position: relative;
            overflow: hidden;

            &:before,
            &:after {
                opacity: 0;
                transition: opacity .5s ease;
                content: '';
                position: absolute;
                height: 50px;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
                left: 0;
                width: 100%;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
                transform: rotate(180deg);
            }

            &[data-debut="false"]:before,
            &[data-fin="false"]:after {
                // opacity: .5;
                display: none;
            }

            [data-zone="scroll"] {
                position: absolute;
                inset: 0;
                right: -10vw;
                overflow-y: auto;
                scroll-behavior: smooth;

                [data-zone="numero"] {
                    img {
                        display: none;
                    }

                    user-select: none;


                    transition: all .3s ease;
                    color: var(--color-text-default);
                    text-decoration: none;
                    display: block;
                    text-align: center;
                    margin-right: 10vw;
                    font-family: var(--police-sans-serif);
                    font-weight: normal;
                    font-variation-settings: "wght" 900;
                    opacity: 0.5;

                    &:hover,
                    &:active,
                    &:focus,
                    &[data-selected="true"] {
                        outline: 0;
                        opacity: 1;
                    }
                }
            }
        }

        [data-zone="numero"] {
            transition: all .2s ease;
            font-size: 5vw;
            line-height: 1em;
            // line-height: 0.7em;

            &[data-niveau="4"] {
                font-size: 8vw;
                // line-height: 1em;
            }

            &[data-niveau="3"] {
                font-size: 7vw;
                // line-height: 0.9em;
            }

            &[data-niveau="2"] {
                font-size: 6vw;
                // line-height: 0.8em;
            }

        }
    }

    [data-zone="slider"] {
        [data-zone="slide"] {
            figure {
                margin: 0 !important;
            }
        }

        @include grilleSpan(5, 6);
        // [data-zone="slide"] {
        //     &:last-of-type {
        //         [data-action="next"][href=""] {
        //             display: none;
        //         }
        //     }
        // }
        // [data-zone="slide"] {
        //     &:first-of-type {
        //         [data-action="prev"][href=""] {
        //             display: none;
        //         }
        //     }
        // }
    }
}